import { NextResponse } from "next/server";

interface CustomError extends Error {
  statusCode?: number;
}

export const nextErrorHandler = (err: unknown) => {
  if (err instanceof Error) {
    return NextResponse.json(
      { error: err.message },
      { status: (err as CustomError).statusCode || 500 },
    );
  } else {
    return NextResponse.json(
      { error: "An unexpected error occurred" },
      { status: 500 },
    );
  }
};
